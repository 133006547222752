import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()

  const dns = runtimeConfig.public?.sentryURL
  const environment = runtimeConfig.public?.environment

  if (!dns) {
    console.warn('Missing Sentry dns')
    return
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    environment,
    dsn: dns,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      /*new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),*/
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
  })
})