<template>
  <div>
    <NuxtLayout name="error">
      <div>
        <NavigationMenu :external="true"/>
        <div class="page-error page-light p-top-header page-min-height">
          <div class="row-4-desk">
          <h1 class="title-l-1">Uncharted<br> territory</h1>
           <template v-if="error.statusCode === 500">
        <div
          class="text-m-1 m-top-m-1 page-error-text"
          v-html="$t('errors.500.text')
             
          "
        />
        </template>
        <template v-else>
          <p class="page-error-text m-top-m-1 text-m-1 m-bottom-m-1">
            Some of the best discoveries come from the unexpected. You’ve stumbled upon an uncharted area of our site, but rest assured, there’s much more to explore in our research pages.
          </p>
          <div class="page-error-link">
           <NuxtLink
            :to="localePath({ name: 'research' })"
            :external="true"
            class="button-rect-1-positive is-big"
          >
            <span class="is-text-uppercase">Discover our research</span>
          </NuxtLink>
        </div>
        </template>
        </div>
      </div>
      </div>
      
    </NuxtLayout>
  </div>
</template>

<script setup>
const fromError = setFromError()

const props = defineProps({
  error: Object,
})
console.error(props.error)

onMounted(() => {
  console.log(fromError.value)
})

onUnmounted(() => {
  fromError.value = true
  console.log(fromError.value)
})

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)
</script>
