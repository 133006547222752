import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getNewsPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getNewsPage($locale: SiteLocale) {
    newsPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
    }
    allNews(locale: $locale) {
      id
      title
      slug
      date
      image {
        id
        responsiveImage(
          imgixParams: { ar: "1", auto: format, q: "60", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`

export const getNews = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getNews(
    $locale: SiteLocale
    $filter: NewsModelFilter
    $filterOthers: NewsModelFilter
  ) {
    news(locale: $locale, filter: $filter) {
      seo {
        ...SeoFragment
      }
      title
      slug
      date
      intro
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
      author {
        name
        id
      }
      body {
        value
        blocks {
          ... on FocusQuoteBlockRecord {
            id
            text
            author
          }
          ... on MediaBlockCaptionRecord {
            id
            videoUrl
            caption
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
      }
    }
    allNews(locale: $locale, filter: $filterOthers, first: 3) {
      id
      title
      slug
      date
      image {
        id
        responsiveImage(
          imgixParams: { ar: "1", auto: format, q: "60", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`
