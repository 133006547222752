import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getClinical = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getClinical($locale: SiteLocale) {
    allIndustries(locale: $locale) {
      title
      slug
      imagePreviewListing {
        url
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
    industriesListBlock(locale: $locale) {
      id
      block {
        title
        text
      }
    }
    clinicalAreaPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
      id
      aree {
        title
        name
        id
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      body {
        ... on IndustriesBlockRecord {
          id
          title
          text
        }
        ... on CtaBlockRecord {
          id
          ctaLink
          ctaLabel
          title
          subtitle
          videoUrl
          slim
        }
        ... on ClientiLoghiBlockRecord {
          id
          title
        }
        ... on GridSectionRecord {
          id
          title
          alignment
          gridRows {
            id
            items {
              ... on GridBlockItemRecord {
                id
                image {
                  id
                  responsiveImage(
                    imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                  ) {
                    ...ResponsiveImageFragment
                  }
                }
                linkVideo
                linkVideoPreview
                fullWidth
                smallHeight
                sideText
              }
              ... on GridBlockItemTextRecord {
                id
                text
                fullWidth
                fontSizeSmall
                alignment
                link {
                  id
                  linkLabel
                  linkUrl
                  internalLink {
                    ... on ContentRecord {
                      id
                      slug
                    }
                    ... on ContentsListingRecord {
                      id
                      name
                    }
                    ... on ExpertiseListingRecord {
                      id
                      name
                    }
                    ... on ExpertiseRecord {
                      id
                      slug
                    }
                    ... on IndustryListingRecord {
                      id
                      name
                    }
                    ... on IndustryRecord {
                      id
                      slug
                    }
                    ... on SolutionRecord {
                      id
                      slug
                    }
                    ... on SolutionsListingRecord {
                      id
                      name
                    }
                    ... on StudioPageRecord {
                      id
                      title
                    }
                    ... on AreYouAnAgencyRecord {
                      id
                    }
                    ... on ContactPageRecord {
                      id
                    }
                  }
                }
              }
              ... on TwoColumnsGridTextRecord {
                id
                text
                title
                titleAsSimpleText
              }
              ... on ServicesBlockRecord {
                id
                title
                texts {
                  id
                  title
                  text
                }
              }
            }
          }
        }
      }
    }
    clientiSection(locale: $locale) {
      title
      id
      imagesList {
        url
        alt
      }
    }
  }
`
