import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'

export const getPrivacyPage = gql`
  ${seoFragment}
  query GetPrivacyPage($locale: SiteLocale) {
    privacyPolicy(locale: $locale) {
      title
      seo {
        ...SeoFragment
      }
      body
    }
  }
`

export const getContactsPage = gql`
  ${seoFragment}
  query GetContactsPage($locale: SiteLocale) {
    contactPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      introText
      contactsList {
        text
      }
      formTitle
    }
  }
`

