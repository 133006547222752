import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { projectListingFragment } from '../fragments/projects.js'
import { seoFragment } from '../fragments/seo.js'

export const getSolutionsListingPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetSolutionsListingPage($locale: SiteLocale) {
    solutionsListing(locale: $locale) {
      id
      title
      subtitle
      introText
      seo {
        ...SeoFragment
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
      body {
        ... on GridSectionRecord {
          id
          title
          alignment
          gridRows {
            id
            items {
              ... on GridBlockItemRecord {
                id
                image {
                  id
                  responsiveImage(
                    imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                  ) {
                    ...ResponsiveImageFragment
                  }
                }
                linkVideo
                linkVideoPreview
                fullWidth
                smallHeight
                sideText
              }
              ... on GridBlockItemTextRecord {
                id
                text
                fullWidth
                fontSizeSmall
                alignment
                link {
                  id
                  linkLabel
                  linkUrl
                  internalLink {
                    ... on ContentRecord {
                      id
                      slug
                    }
                    ... on ContentsListingRecord {
                      id
                      name
                    }
                    ... on ExpertiseListingRecord {
                      id
                      name
                    }
                    ... on ExpertiseRecord {
                      id
                      slug
                    }
                    ... on IndustryListingRecord {
                      id
                      name
                    }
                    ... on IndustryRecord {
                      id
                      slug
                    }
                    ... on SolutionRecord {
                      id
                      slug
                    }
                    ... on SolutionsListingRecord {
                      id
                      name
                    }
                    ... on StudioPageRecord {
                      id
                      title
                    }
                    ... on AreYouAnAgencyRecord {
                      id
                    }
                    ... on ContactPageRecord {
                      id
                    }
                  }
                }
              }
              ... on TwoColumnsGridTextRecord {
                id
                text
                title
                titleAsSimpleText
              }
              ... on ServicesBlockRecord {
                id
                title
                texts {
                  id
                  title
                  text
                }
              }
            }
          }
        }
        ... on ClinicalAreasBlockRecord {
          id
          title
          text
        }
        ... on IndustriesBlockRecord {
          id
          title
          text
        }
        ... on CtaBlockRecord {
          id
          ctaLink
          ctaLabel
          title
          subtitle
          videoUrl
          slim
        }
        ... on ClientiLoghiBlockRecord {
          id
          title
        }
      }
    }
    allSolutions(locale: $locale, first: 100) {
      id
      title
      slug
      caption
      titlePreviewListing
      linkPreviewVideoListing
      imagePreviewListing {
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
    industriesListBlock(locale: $locale) {
      id
      block {
        title
        text
      }
    }
    allIndustries(locale: $locale) {
      title
      slug
      imagePreviewListing {
        url
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
    clinicalAreaPage(locale: $locale) {
      id
      aree {
        title
        name
        id
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
    }
    clinicalAreasListBlock(locale: $locale) {
      id
      block {
        title
        text
      }
    }
    clientiSection(locale: $locale) {
      title
      id
      imagesList {
        url
        alt
      }
    }
  }
`

export const getSolution = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetSolution($locale: SiteLocale, $filter: SolutionModelFilter) {
    solution(locale: $locale, filter: $filter) {
      _allSlugLocales(fallbackLocales: en) {
        value
        locale
      }
      seo {
        ...SeoFragment
      }
      id
      title
      slug
      hero {
        id
        whiteText
        title
        text
        previewVideo
        overlayFullVideo
        alignment
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
        link {
          id
          linkLabel
          linkUrl
          internalLink {
            ... on ContentRecord {
              id
              slug
            }
            ... on ContentsListingRecord {
              id
              name
            }
            ... on ExpertiseListingRecord {
              id
              name
            }
            ... on ExpertiseRecord {
              id
              slug
            }
            ... on IndustryListingRecord {
              id
              name
            }
            ... on IndustryRecord {
              id
              slug
            }
            ... on SolutionRecord {
              id
              slug
            }
            ... on SolutionsListingRecord {
              id
              name
            }
            ... on StudioPageRecord {
              id
              title
            }
            ... on AreYouAnAgencyRecord {
              id
            }
            ... on ContactPageRecord {
              id
            }
          }
        }
      }
      introText
      alignIntroText
      body {
        ... on GridSectionRecord {
          id
          title
          alignment
          gridRows {
            id
            items {
              ... on GridBlockItemRecord {
                id
                image {
                  id
                  responsiveImage(
                    imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                  ) {
                    ...ResponsiveImageFragment
                  }
                }
                linkVideo
                linkVideoPreview
                fullWidth
                smallHeight
                sideText
                align
              }
              ... on GridBlockItemTextRecord {
                id
                text
                fullWidth
                fontSizeSmall
                alignment
                link {
                  id
                  linkLabel
                  linkUrl
                  internalLink {
                    ... on ContentRecord {
                      id
                      slug
                    }
                    ... on ContentsListingRecord {
                      id
                      name
                    }
                    ... on ExpertiseListingRecord {
                      id
                      name
                    }
                    ... on ExpertiseRecord {
                      id
                      slug
                    }
                    ... on IndustryListingRecord {
                      id
                      name
                    }
                    ... on IndustryRecord {
                      id
                      slug
                    }
                    ... on SolutionRecord {
                      id
                      slug
                    }
                    ... on SolutionsListingRecord {
                      id
                      name
                    }
                    ... on StudioPageRecord {
                      id
                      title
                    }
                    ... on AreYouAnAgencyRecord {
                      id
                    }
                    ... on ContactPageRecord {
                      id
                    }
                  }
                }
              }
              ... on TwoColumnsGridTextRecord {
                id
                text
                title
                titleAsSimpleText
              }
              ... on ServicesBlockRecord {
                id
                title
                texts {
                  id
                  title
                  text
                }
              }
            }
          }
        }
        ... on ServicesBlockRecord {
          id
          title
          texts {
            id
            title
            text
          }
        }
        ... on MediaCarouselRecord {
          id
          isVertical
          slides {
            id
            linkVideo
            linkVideoPreview
            image {
              id
              responsiveImage(
                imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
            portrait: image {
              id
              responsiveImage(
                imgixParams: { ar: "4:5", fit: crop, w: "2000", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on CtaBlockRecord {
          id
          ctaLink
          ctaLabel
          title
          subtitle
          videoUrl
          slim
        }
        ... on AnimatedAccordionRecord {
          id
          title
          accordionsList {
            id
            text
            title
            videoUrl
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on BenefitsBlockRecord {
          id
          title
          texts {
            id
            title
            text
          }
        }
        ... on ApplicationsBlockRecord {
          id
          title
          texts {
            id
            text
          }
        }
        ... on ClinicalAreasBlockRecord {
          id
          title
          text
        }
        ... on IndustriesBlockRecord {
          id
          title
          text
        }
        ... on ClientiLoghiBlockRecord {
          id
          title
        }
      }
      titleProgettiCorrelati
      bigTitleCorrelati
      showLogosBlock
      showAwardsBlock
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    clientiSection(locale: $locale) {
      title
      id
      imagesList {
        url
        alt
      }
    }
    industriesListBlock(locale: $locale) {
      id
      block {
        title
        text
      }
    }
    allIndustries(locale: $locale) {
      title
      slug
      imagePreviewListing {
        url
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
    clinicalAreaPage(locale: $locale) {
      id
      aree {
        title
        name
        id
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
    }
    clinicalAreasListBlock(locale: $locale) {
      id
      block {
        title
        text
      }
    }
  }
`
