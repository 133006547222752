<template>
  <ul class="nav-menu-links" ref="menuLinks" data-animate="menu-links">
    <!-- Prodotti -->
    <!-- <li>
      <NuxtLink
        class="nav-menu-link text-l-1"
        :to="localePath({ name: 'projects' })"
        data-animate="menu-link"
        ><span>{{ $t('menu.menu-nav.works') }}</span></NuxtLink
      >
    </li>
    <li>
      <NuxtLink
        class="nav-menu-link text-l-1"
        :to="localePath({ name: 'expertise' })"
        data-animate="menu-link"
        ><span>{{ $t('menu.menu-nav.expertise') }}</span></NuxtLink
      >
    </li>
    <li>
      <NuxtLink
        class="nav-menu-link text-l-1"
        :to="localePath({ name: 'technology' })"
        data-animate="menu-link"
        ><span>{{ $t('menu.menu-nav.technology') }}</span></NuxtLink
      >
    </li>
    <li>
      <NuxtLink
        class="nav-menu-link text-l-1"
        :to="localePath({ name: 'studio' })"
        data-animate="menu-link"
        ><span>{{ $t('menu.menu-nav.studio') }}</span></NuxtLink
      >
    </li>
    <li>
      <NuxtLink
        class="nav-menu-link text-l-1"
        :to="localePath({ name: 'blog' })"
        data-animate="menu-link"
        ><span>{{ $t('menu.menu-nav.blog') }}</span></NuxtLink
      >
    </li>
    <li>
      <NuxtLink
        class="nav-menu-link text-l-1"
        :to="localePath({ name: 'contacts' })"
        data-animate="menu-link"
        ><span>{{ $t('menu.menu-nav.contacts') }}</span></NuxtLink
      >
    </li>-->

    <li v-for="link in links" :key="link.key" :class="link.class">
      <!-- Link -->
      <NuxtLink
        v-if="link.route"
        class="nav-menu-link link-menu-1"
        :to="localePath(link.route)"
        data-animate="menu-link"
        :external="external"
        ><span>{{
          link.title ? link.title : $t(`menu.menu-nav.${link.key}`)
        }}</span></NuxtLink
      >

      <!-- Links Back -->
      <button
        v-else-if="link.back"
        class="link-menu-1 --ico-back"
        @click="openSubmenu(link.action)"
        data-animate="menu-link"
      >
        <img
          src="~/assets/img/ico/ico-nav-arrow-back.svg"
          alt="Ico nav arrow back"
          width="16"
          height="19"
        />
        <span>{{ $t(`menu.menu-nav.${link.key}`) }}</span>
      </button>
      <!-- Links Forward -->
      <button
        v-else
        class="nav-menu-link link-menu-1 --link-menu-forward"
        @click="openSubmenu(link.action)"
        data-animate="menu-link"
      >
        <span>{{ link.title || $t(`menu.menu-nav.${link.key}`) }}</span>
        <!-- <img
          src="~/assets/img/ico/ico-nav-arrow-forward.svg"
          alt="Ico nav arrow forward"
          width="16"
          height="19"
        />-->
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavigationMenuLinks',
}
</script>

<script setup>
import { gsap } from 'gsap'

const emit = defineEmits(['open-sub-menu', 'open-search'])

const props = defineProps({
  links: Array,
  show: Boolean,
  external: {
    type: Boolean,
    default: false,
  },
})

const menuLinks = ref(null)

const tl = ref(null)

// Animazioni
const animateIn = (params) => {
  const parent = menuLinks.value
  const links = parent.querySelectorAll('[data-animate="menu-link"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })
  tl.set(parent, { autoAlpha: 1, position: 'relative' })
  tl.to(links, {
    y: '0%',
    stagger: 0.05,
    opacity: 1,
  })

  return tl
}

const animateOut = (params) => {
  const parent = menuLinks.value
  const links = parent.querySelectorAll('[data-animate="menu-link"]')

  const tl = gsap.timeline({
    paused: true,
    onStart: () => {
      gsap.set(parent, { position: 'absolute' })
    },
    onComplete: () => {
      tl.kill()
      gsap.set([parent, links], {
        clearProps: 'all',
      })
    },
    ...params,
  })
  tl.to(links, {
    y: '101%',
    opacity: 0,
    stagger: 0.05,
  })
  tl.set(parent, { autoAlpha: 0 })

  return tl
}

const openSubmenu = (action) => emit('open-sub-menu', action)

watch(
  () => props.show,
  (show) => {
    if (!tl.value?.isActive()) {
      tl.value = show ? animateIn() : animateOut()
      tl.value.duration(0.5).play()
    }
  }
)

defineExpose({
  animateIn,
  animateOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
