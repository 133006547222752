export const navMenu = {
  linksMain: [
    {
      key: 'research',
      route: { name: 'research' },
    },
    {
      key: 'lab',
      route: { name: 'lab' },
    },
    {
      key: 'team',
      route: { name: 'team' },
    },
    {
      key: 'publications',
      route: { name: 'publications' },
    },
    /*{
      key: 'news',
      route: { name: 'news' },
    },*/
    {
      key: 'contacts',
      route: { name: 'contacts' },
    },
  ],
  linksBack: [
    {
      key: 'back',
      class: 'nav-menu__links__separator --small-link --top',
      back: true,
      action: 'main',
    },
  ],
  linksSubMenuExpertise: [
    {
      key: 'all',
      class: 'nav-menu__links__separator --bottom --small-link',
      route: { name: 'expertise' },
    },
  ],
  linksSubMenuContents: [
    {
      key: 'all',
      class: 'nav-menu__links__separator --bottom --small-link',
      route: { name: 'contents' },
    },
  ],
  linksSubMenuSolutions: [
    {
      key: 'all',
      class: 'nav-menu__links__separator --bottom --small-link',
      route: { name: 'solutions' },
    },
  ],
}
