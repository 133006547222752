import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getLab = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getLab($locale: SiteLocale) {
    lab(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
      wideMedia {
        videoUrl
        id
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      section {
        id
        row {
          title
          text
          id
          image {
            id
            responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
              ...ResponsiveImageFragment
            }
          }
        }
        imageWide {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
    }
  }
`
