<template>
  <div class="nav-large__wrap flex is-justify-space-between">
    <!-- Logo -->
    <div class="nav-large__logo-wrap">
      <NuxtLink
        :to="localePath({ name: 'index' })"
        :external="external"
        class="nav-large__logo"
      >
        <img
          :src="isLight ? LogoWhite : Logo"
          class="nav-large__logo-glyph"
          height="30"
          :alt="runtimeConfig.public.siteName"
        />
      </NuxtLink>
    </div>

    <div class="nav-large__list__wrap">
      <div class="nav-large__list__container">
        <ul class="nav-large__list">
          <li class="nav-large__list-main-link">
            <nuxt-link
              :to="localePath({ name: 'research' })"
              class="text-menu"
              :external="external"
              :class="{
                '--active': route.name && route.name.includes('research'),
              }"
            >
              Research
            </nuxt-link>
          </li>
          <li class="nav-large__list-main-link">
            <nuxt-link
              :to="localePath({ name: 'lab' })"
              class="text-menu"
              :external="external"
              :class="{ '--active': route.name && route.name.includes('lab') }"
            >
              Lab
            </nuxt-link>
          </li>
          
        </ul>
        <ul class="nav-large__list">
          <li class="nav-large__list-main-link">
            <nuxt-link
              :to="localePath({ name: 'publications' })"
              class="text-menu"
              :external="external"
              :class="{
                '--active': route.name && route.name.includes('publications'),
              }"
            >
              Publications
            </nuxt-link>
          </li>
          <li class="nav-large__list-main-link">
            <nuxt-link
              :to="localePath({ name: 'team' })"
              class="text-menu"
              :external="external"
              :class="{ '--active': route.name && route.name.includes('team') }"
            >
              Team
            </nuxt-link>
          </li>
          <!--<li class="nav-large__list-main-link">
            <nuxt-link
              :to="localePath({ name: 'news' })"
              class="text-menu"
              :external="external"
              :class="{ '--active': route.name && route.name.includes('news') }"
            >
              News
            </nuxt-link>
          </li>-->
        </ul>
      </div>
      <div class="nav-large__contatcs__wrap">
        <NuxtLink
          :to="localePath({ name: 'contacts' })"
          :external="external"
          class="nav-large__contatcs__link"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        >
          <span class="is-text-uppercase">Get in touch</span>
          <img
            :src="isHover ? IconPlusWhite : IconPlus"
            class="nav-large__plus"
            height="14"
            :alt="runtimeConfig.public.siteName"
          />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuLarge',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Logo from '@/assets/img/logos/symmune.svg'
import LogoWhite from '@/assets/img/logos/symmune-white.svg'

import IconPlus from '@/assets/img/ico/ico-plus.svg'
import IconPlusWhite from '@/assets/img/ico/ico-plus-white.svg'

import IconArrow from '@/assets/img/ico/ico-arrow-next-circle-blu.svg'

gsap.registerPlugin(ScrollTrigger)

const runtimeConfig = useRuntimeConfig()
const route = useRoute()

const lenis = inject('lenis')

const nav = ref(null)
const menuButton = ref(null)
const logo = ref(null)

const duration = 0.4

const isHover = ref(false)

const isNewPage = ref(true)

const showSubmenu = ref(null)
const openSubmenu = ref(false)

const props = defineProps({
  isLight: Boolean,
  submenu: Object,
  external: {
    type: Boolean,
    default: false,
  },
})


watch(
  () => route.path,
  () => {
    isNewPage.value = true
    //animateMenuOut().play()

    /*if (isHover.value) {
      isHover.value = true
    }*/
  }
)

onMounted(() => {
  isNewPage.value = false

  /*const tl = gsap.timeline({
    ease: 'power2.out',
    paused: true,
  })*/

  //tl.to(logo.value, { x: '-115%' })
  //tl.to(logoDot.value, { x: '-148px', autoAlpha: 0 }, '<')
  //tl.to(logoMedicalWrap.value, {x: '-160px'}, '<')
  //tl.to(logoMedical.value, {x: '-155px'}, '<')
  //tl.to(logoMedical.value, {x: '-272px'}, '0.1')
  /* tl.to(
    nav.value,
    {
      x: '100%',
    },
    '<'
  )
  tl.to(menuButton.value, { x: '0%', autoAlpha: 1 }) */

  ScrollTrigger.create({
    id: 'scrollMenu',
    start: '100px top',
    onEnter: () => {
      if (!isHover.value) {
        //tl.play()
        document.body.classList?.add('--scroll-menu')
      } /*else {
       //animateMenuOut()
      }*/

      isNewPage.value = false
    },
    onLeaveBack: () => {
      if (isNewPage.value) {
        isNewPage.value = false
      } else {
        if (isHover.value) {
          //tl.pause()
        } else {
          //tl.reverse()
          document.body.classList?.remove('--scroll-menu')
        }
      }
    },
  })
})

/*const animateMenuIn = () => {
  const tl = gsap.timeline({
    totalDuration: 0.6,
    ease: 'power2.out',
    paused: true,
  })

  tl.to(menuButton.value, {
    x: '100%',
    autoAlpha: 0,
    duration: 0.1,
  })
  tl.to(nav.value, { x: '0%' })

  return tl
}*/

/*const animateMenuOut = () => {
  const tl = gsap.timeline({
    totalDuration: 0.6,
    ease: 'power2.out',
    paused: true,
  })

  tl.to(nav.value, { x: '100%' })
  tl.to(menuButton.value, {
    x: '0%',
    autoAlpha: 1,
    duration: 0.1,
  })

  return tl
}*/

const mouseLeave = () => {
  isHover.value = false
}

const navigate = () => {
  isHover.value = false
}
</script>

<style lang="scss">
@import './style.scss';
</style>
