import revive_payload_client_n8Y9B2PYZ5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mvSkB1xpF5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kbk42p9bO7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0QxWSSdJAB from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.24.4_vite@5.4.10_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_37GnE7YtQg from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8y27EvHNPO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tNp3TXLGDE from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CauGYkRFec from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_JLPYzNw7BH from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.24.4_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_eMRrIq84PB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_0f8411ca_dUbtV07GWJ from "/opt/build/repo/.nuxt/templates.pwa.client.0f8411ca.ts";
import plugin_3B8F7uqV2m from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_rollup@4.24.4_typescript@5.6.3_vue@3.5.12/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_pbRqYZypqg from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.24.4_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_npX2x2vknn from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.24.4_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import directives_8CcCirWtnE from "/opt/build/repo/plugins/directives.ts";
import plugin_Y14TYr3J6A from "/opt/build/repo/plugins/plugin.ts";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
import tracking_client_fEUopUxxSj from "/opt/build/repo/plugins/tracking.client.js";
export default [
  revive_payload_client_n8Y9B2PYZ5,
  unhead_mvSkB1xpF5,
  router_kbk42p9bO7,
  _0_siteConfig_0QxWSSdJAB,
  payload_client_37GnE7YtQg,
  navigation_repaint_client_8y27EvHNPO,
  check_outdated_build_client_tNp3TXLGDE,
  chunk_reload_client_CauGYkRFec,
  plugin_vue3_JLPYzNw7BH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eMRrIq84PB,
  templates_pwa_client_0f8411ca_dUbtV07GWJ,
  plugin_3B8F7uqV2m,
  switch_locale_path_ssr_pbRqYZypqg,
  i18n_npX2x2vknn,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  directives_8CcCirWtnE,
  plugin_Y14TYr3J6A,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q,
  tracking_client_fEUopUxxSj
]