import { ref } from 'vue'
import { defineStore } from 'pinia'
import queries from '@/graphql/dato/queries/index.js'

export const useStore = defineStore('store', () => {
  const state = ref(null)
  const { locale } = useI18n()

  const updateState = (value) => {
    state.value = value
  }

  /*const fetchState = async () => {
    const { data } = await useAsyncQuery(queries.site.testQuery, {
      locale: locale.value,
    })
    state.value = data.value.entries
  }*/

  return {
    state,
    fetchState,
    updateState,
  }
})
