import { default as contactsQ2Ks7vPefgMeta } from "/opt/build/repo/pages/contacts.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as labukpEjnZBQtMeta } from "/opt/build/repo/pages/lab.vue?macro=true";
import { default as indexPPJe7iS3pHMeta } from "/opt/build/repo/pages/news/[slug]/index.vue?macro=true";
import { default as index8k4xMa35gmMeta } from "/opt/build/repo/pages/news/index.vue?macro=true";
import { default as publicationsHvHrOaJCVKMeta } from "/opt/build/repo/pages/publications.vue?macro=true";
import { default as researchSFScNlVmHgMeta } from "/opt/build/repo/pages/research.vue?macro=true";
import { default as index69ntOP6c4xMeta } from "/opt/build/repo/pages/team/[slug]/index.vue?macro=true";
import { default as indexfyFJM7qkVcMeta } from "/opt/build/repo/pages/team/index.vue?macro=true";
import { default as component_45stubMhO4CFyY6uMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMhO4CFyY6u } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.24.4_sass@1.80.6_stylelint@14.16.1_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "contacts___en",
    path: "/contacts",
    meta: contactsQ2Ks7vPefgMeta || {},
    component: () => import("/opt/build/repo/pages/contacts.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "lab___en",
    path: "/lab",
    meta: labukpEjnZBQtMeta || {},
    component: () => import("/opt/build/repo/pages/lab.vue")
  },
  {
    name: "news-slug___en",
    path: "/news/:slug()",
    meta: indexPPJe7iS3pHMeta || {},
    component: () => import("/opt/build/repo/pages/news/[slug]/index.vue")
  },
  {
    name: "news___en",
    path: "/news",
    meta: index8k4xMa35gmMeta || {},
    component: () => import("/opt/build/repo/pages/news/index.vue")
  },
  {
    name: "publications___en",
    path: "/publications",
    meta: publicationsHvHrOaJCVKMeta || {},
    component: () => import("/opt/build/repo/pages/publications.vue")
  },
  {
    name: "research___en",
    path: "/research",
    meta: researchSFScNlVmHgMeta || {},
    component: () => import("/opt/build/repo/pages/research.vue")
  },
  {
    name: "team-slug___en",
    path: "/team/:slug()",
    meta: index69ntOP6c4xMeta || {},
    component: () => import("/opt/build/repo/pages/team/[slug]/index.vue")
  },
  {
    name: "team___en",
    path: "/team",
    meta: indexfyFJM7qkVcMeta || {},
    component: () => import("/opt/build/repo/pages/team/index.vue")
  },
  {
    name: component_45stubMhO4CFyY6uMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubMhO4CFyY6u
  }
]