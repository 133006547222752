import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getHome = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getHome($locale: SiteLocale) {
    home(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      id
      background {
        id
        videoUrl
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      rows {
        title
        text
        id
      }
    }
  }
`
