<template>
  <nav class="nav-menu" :class="{ '--open': openOverlay }">
    <div ref="header" class="nav-menu__header">
      <NavigationLarge
        class="nav-menu__large"
        :is-light="isLight"
        v-if="matchMedia"
        :external="external"
      />
      <NavigationSmall
        class="nav-menu__small"
        @open-menu="toggleOverlay"
        :is-light="isLight"
        v-if="!matchMedia"
        :external="external"
        ref="mobileMenu"
        :is-active="openOverlay"
      />
    </div>
    <!-- Overlay -->
    <WidgetOverlayFullScreen
      class="nav-menu__overlay hide-for-large-2"
      ref="overlay"
      :right="true"
    >
      <div class="nav-menu__overlay__wrap" data-lenis-prevent>
        <NavigationMenuLinks
          class="nav-menu__links --main"
          ref="mainMenu"
          :links="linksMain"
          :external="external"
        />
        <div ref="menuLang" class="nav-menu__overlay__ref-wrap">
          <p v-html="$t('menu.menu-nav.ref')" class="text-m-1" />
        </div>

        <!--  <client-only>
        <div v-if="showSubMenu === false || showSubMenu === null || showSubMenu === 'main'" ref="menuLang" class="nav-menu__lang-wrap is-text-uppercase">
          <ul>
            <li v-for="locale in locales.filter((code) => code.code !== current.code)" :key="locale.code">
            <a
          :href="switchLocalePath(locale.code)"
        >
          <span> {{ locale.code }}</span>
          
        </a></li>
          </ul>
        </div>
         </client-only>-->
      </div>
    </WidgetOverlayFullScreen>
  </nav>
</template>

<script>
export default {
  name: 'NavigationMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { debounce } from '~/assets/js/utils.js'

import queries from '@/graphql/dato/queries/index.js'

import { navMenu } from '@/bucket/navigation.js'

const { locale, locales } = useI18n()

const matchMedia = useHelpers().evalMatchMedia('(min-width: 1100px)')
const route = useRoute()

const lightTheme = setLightTheme()

const isLight = ref(false)

const overlay = ref(null)
const mainMenu = ref(null)
const openOverlay = ref(false)
const subMenuExpertise = ref(null)
const subMenuTechnology = ref(null)

const showSubMenu = ref(false)

const menuLang = ref(null)

const tl = ref(null)
const mobileMenu = ref(null)

// Hide / Show nav
const lastScrollPosition = ref(150)
const header = ref(null)
const defaultLightTheme = ref(false)

const current = computed(() => {
  return locales.value.find(({ code }) => code === locale.value)
})

const props = defineProps({
  external: {
    type: Boolean,
    default: false,
  },
})

/*const query = queries.navigation.getNavigationSubmenus
const variables = computed(() => ({
  locale: locale.value,
}))*/
//const { data } = await useGraphql(query, variables)
// Link statici e non
const linksMain = navMenu.linksMain

watch(
  () => lightTheme.value,
  (lightTheme) => {
    if (lightTheme) {
      isLight.value = true
    } else {
      isLight.value = false
    }
  }
)

// Gestisco Overlay
const toggleOverlay = () => {
  if (tl.value?.isActive()) {
    tl.value.progress(1)
  }
  openOverlay.value = !openOverlay.value
}
watch(
  () => openOverlay.value,
  (openOverlay) => {
    console.log(mobileMenu.value)
    tl.value = openOverlay
      ? animateOverlayIn().duration(1)
      : animateOverlayOut().duration(2)
    tl.value.play()
    showSubMenu.value = null
  }
)
watch(
  () => route.path,
  () => {
    if (openOverlay.value) {
      toggleOverlay()
    }
    if (route?.name?.includes('index') || route?.name?.includes('contacts')) {
      if (document.body.classList?.contains('--light-theme')) {
        lightTheme.value = true
        defaultLightTheme.value = true
      } else {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
        defaultLightTheme.value = true
      }
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
      defaultLightTheme.value = false
    }
  }
)

// Gestisco Submenu
const toggleSubMenu = (action) => {
  showSubMenu.value = action
}

// Animazioni
const animateOverlayIn = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 0.7,

    onStart: () => {
      document.body.setAttribute('data-menu-overlay-open', true)
    },
    onComplete: () => {
      tl.kill()
    },
  })
  tl.add(overlay.value.animateIn().play(), '<')
  if (mainMenu.value) {
    tl.add(mainMenu.value.animateIn().play())
  }
  if (menuLang.value) {
    tl.fromTo(menuLang.value, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 })
  }

  return tl
}

const animateOverlayOut = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 2,
    onComplete: () => {
      document.body.removeAttribute('data-menu-overlay-open', false)

      tl.kill()
    },
  })
  tl.add(overlay.value.animateOut().play(), '<')

  if (mainMenu.value) {
    tl.add(mainMenu.value.animateOut().play())
  }
  if (menuLang.value) {
    tl.to(menuLang.value, { autoAlpha: 0, duration: 0.6 })
  }

  return tl
}

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  onResize()
  defaultLightTheme.value = lightTheme.value

  gsap.to(menuLang.value, { autoAlpha: 1, duration: 0.6 })
  window.addEventListener('scroll', handleScroll)
  if (route?.name?.includes('index')) {
    defaultLightTheme.value = true
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

const onResize = () => {
  openOverlay.value = false
}

const handleScroll = () => {
  //console.log('inizio handle', defaultLightTheme.value)
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop
  if (currentScrollPosition > lastScrollPosition.value) {
    // Scrolling down
    //console.log(currentScrollPosition, lastScrollPosition)
    header.value.classList.add('hide-nav')
    if (defaultLightTheme.value === true) {
      if (document.body.classList?.contains('--light-theme')) {
        lightTheme.value = true
      } else {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
      }
    }
  } else {
    // Scrolling up
    header.value.classList.remove('hide-nav')
    if (currentScrollPosition > 100) {
      if (document.body.classList?.contains('--light-theme')) {
        document.body.classList?.remove('--light-theme')
        lightTheme.value = false
      }
    } else {
      if (defaultLightTheme.value === true) {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
        //console.log('ultimo if', defaultLightTheme.value)
      }
    }
  }
  lastScrollPosition.value =
    currentScrollPosition <= 100 ? 100 : currentScrollPosition
  //closeOverlay()
}
</script>

<style lang="scss">
@import './style.scss';
</style>
