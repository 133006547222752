import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getResearch = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getResearch($locale: SiteLocale) {
    research(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
      wideMedia {
        videoUrl
        id
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      section {
        title
        text
        id
        category
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
        teamRelation {
          id
          slug
          name
        }
      }
      fundsTitle
      fundsSubtitle
      fundsImages {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`
