export const appPageTransition = {"name":"page-fade","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"format-detection","content":"telephone=no"},{"name":"google-site-verification","content":""},{"name":"robots","content":"all"}],"link":[{"rel":"preload","href":"https://use.typekit.net/pfn4ehl.css","as":"style","onload":"this.onload=null;this.rel='stylesheet'"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","type":"image/svg+xml","href":"/ico.svg"},{"rel":"apple-touch-icon","href":"/apple-icon-57x57.png","sizes":"57x57"},{"rel":"apple-touch-icon","href":"/apple-icon-60x60.png","sizes":"60x60"},{"rel":"apple-touch-icon","href":"/apple-icon-72x72.png","sizes":"72x72"},{"rel":"apple-touch-icon","href":"/apple-icon-76x76.png","sizes":"76x76"},{"rel":"apple-touch-icon","href":"/apple-icon-114x114.png","sizes":"114x114"},{"rel":"apple-touch-icon","href":"/apple-icon-120x120.png","sizes":"120x120"},{"rel":"apple-touch-icon","href":"/apple-icon-144x144.png","sizes":"144x144"},{"rel":"apple-touch-icon","href":"/apple-icon-152x152.png","sizes":"152x152"},{"rel":"apple-touch-icon","href":"/apple-icon-180x180.png","sizes":"180x180"},{"rel":"icon","type":"image/png","href":"/android-icon-192x192.png","sizes":"192x192"},{"rel":"icon","type":"image/png","href":"/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/favicon-96x96.png","sizes":"96x96"},{"rel":"icon","type":"image/png","href":"/favicon-16x16.png","sizes":"16x16"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true