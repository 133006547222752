import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getPubblication = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getPubblication($locale: SiteLocale) {
    pubblicationPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
    }
    allPubblications(locale: $locale) {
      id
      title
      author
      newsOutlets
      year
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
      pdf {
        url
      }
    }
  }
`
