<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <VitePwaManifest />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index.js'
const { locale } = useI18n()
await useAsyncData(() => useStore().fetchState())


/*watch(
  () => locale.value,
  async () => {
    await useAsyncData(() => useStore().fetchState())
  }
)*/

const { lenis } = useLenis()
provide('lenis', lenis)
</script>

<style>
/* Transizione graduale */
.page-fade-enter-active, .page-fade-leave-active {
  transition: opacity 0.3s ease;
}
.page-fade-enter-from, .page-fade-leave-to {
  opacity: 0;
}
</style>