import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getTeamPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getTeamPage($locale: SiteLocale) {
    teamPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
    }
    allTeams(locale: $locale) {
      id
      name
      role
      bio
      slug
      tel
      mail
      linkedin
      image {
        id
        responsiveImage(
          imgixParams: {
            auto: format
            q: "60"
            fit: crop
            crop: focalpoint
            fpY: "0"
          }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`

export const getTeam = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query getTeam($locale: SiteLocale, $filter: TeamModelFilter) {
    team(locale: $locale, filter: $filter) {
      seo {
        ...SeoFragment
      }
      name
      role
      id
      bio
      slug
      tel
      mail
      linkedin
      position
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
    }
      allTeams(locale: $locale) {
      position
      name
      slug
    }
  }
`
